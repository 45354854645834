import React from 'react';
import {FiEye} from "react-icons/fi";

const Show = () => {
    return (
        <span className='text-white text-xs lg:text-sm' title="نمایش جزئیات"><FiEye size={20}/></span>
    );
};

export default Show;
