import React from 'react';

const Input = ({formik, name, label, placeholder = null, type = "text", dir = 'rtl', onlyNum = false,maxLength = 30000}) => {
    let errorsStyle = formik.errors[name] && formik.touched[name] ? 'border-red-500' : ''


    //Only Type Number
    const onKeyPressHandler = (e) => {
        if(onlyNum){
            if (!/[0-9]/.test(e.key)) {
                e.preventDefault();
            }
        }
    }

    return (
        <div className="mt-3">
            <label htmlFor="first_name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">{label}</label>
            <input dir={dir} {...formik.getFieldProps(name)} maxLength={maxLength} onKeyPress={onKeyPressHandler} type={type} id={name} name={name} className={`${errorsStyle} bg-gray-50 dark:bg-[#1f2937] dark:text-gray-200 dark:border-gray-600 border border-gray-300 text-gray-900 text-sm rounded-lg focus-visible:outline-0 focus-visible:ring-2 focus-visible:ring-indigo-500  block w-full p-2.5`} placeholder={placeholder}/>
            {formik.errors[name] && formik.touched[name] && (
                <p className="mt-2 text-pink-600 text-xs">
                    {formik.errors[name]}
                </p>
            )}
        </div>
    )
}

export default Input;
