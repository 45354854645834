import {createSlice,createAsyncThunk} from '@reduxjs/toolkit'
import axios from "axios";
import {Config, ConfigMessage} from "../../config/Config";
import {JsonToWWWFormData} from "../../utility/JsonToWWWFormData";

//list

export const getAsyncConnection = createAsyncThunk("connections/getAsyncConnection",async (payload,{rejectWithValue})=>{
    try {
        const res = await axios.post(Config.api,JsonToWWWFormData({action : "connection",user_id : payload.id,row_per_page : payload.row_per_page ,page_number : payload.page_number}),
            {
                headers: {'Authorization': 'Bearer ' + sessionStorage.getItem('token')}
            })
        const value = res.data
        return await value;
    }catch (error){
        return rejectWithValue([],error.message)
    }
})



const initialState = {
    connection : [],
    page : 1,
    count : 1,
    error : null,
    loading : false
}

const connectionsSlice = createSlice({
    name : 'connections',
    initialState,
    reducers :{
    },
    extraReducers : {
        [getAsyncConnection.fulfilled] : (state,action)=>{
            return {...state,
                connection : action.payload.result.connection ,
                page : action.payload.result.page,
                count : action.payload.result.count,
                loading: false ,
                error: null}
        },
        [getAsyncConnection.pending] : (state,action)=>{
            return {...state,connection : [] ,loading: true , error: null}
        },
        [getAsyncConnection.rejected] : (state,action)=>{
            return {...state,connection : [] ,loading: false , error: ConfigMessage.error}
        }
    }
})

export const {} = connectionsSlice.actions
export default connectionsSlice.reducer
