import { configureStore } from '@reduxjs/toolkit'
import themeReducer from "./theme/themeSlice";
import counterReducer from "./counter/counterSlice";
import reportReducer from "./report/reportSlice";
import userReducer from "./user/userSlice";
import configReducer from "./config/configSlice";
import appBlockSliceReducer from "./setting/appBlockSlice";
import contactReducer from "./contact/contactSlice";
import eventReducer from "./event/eventSlice";
import metaDataReducer from "./metaData/metaDataSlice";
import connectionsReducer from "./connections/connectionsSlice";
import ipReducer from "./ip/ipSlice";
import adsPropertiesReducer from "./ads/adsProperties";
import adsSettingReducer from "./ads/adsSetting";
import regionsReducer from "./regions/regionsSlice";
import messageReducer from "./message/messageSlice";
import versionReducer from "./setting/versionSlice";
import connectionReducer from "./setting/connectionSlice";
import adsMenuReducer from "./setting/adsMenuSlice";
import toastReducer from "./toast/toastSlice";
import chartReducer from "./chart/chartSlice";
import connectionTypeReducer from "./connectionType/connectionType";

export const store = configureStore({
    reducer: {
        theme : themeReducer,
        counter : counterReducer,
        report : reportReducer,
        user : userReducer,
        config : configReducer,
        appBlock : appBlockSliceReducer,
        contact : contactReducer,
        event : eventReducer,
        metaData : metaDataReducer,
        connections : connectionsReducer,
        ip : ipReducer,
        adsProperties : adsPropertiesReducer,
        adsSetting : adsSettingReducer,
        regions : regionsReducer,
        message : messageReducer,
        version : versionReducer,
        connection : connectionReducer,
        adsMenu : adsMenuReducer,
        toast : toastReducer,
        chart : chartReducer,
        connectionType : connectionTypeReducer
    },
})
