import React, {useEffect} from 'react';
import {Config} from "../../config/Config";
import {formatNumber} from "../../utility/formatNumber";
import {useDispatch, useSelector} from "react-redux";
import {AiOutlineUser, AiOutlineUsergroupAdd, AiOutlineUserSwitch} from "react-icons/ai";
import {getAsyncChart} from "../../features/chart/chartSlice";
import ChartBlockShow from "../../components/chart/chartBlockShow";


const Dashboard = () => {

    const {chart,status} = useSelector(state => state.chart)
    const dispatch = useDispatch()

    useEffect(()=>{
        dispatch(getAsyncChart())
    },[])


    return (
        <div className="flex flex-col md:gap-8 gap-4 sm:mb-0 mb-12">
            <div className="flex flex-col bg-white dark:bg-[#1f2937] dark:shadow-none dark:border dark:border-gray-600 rounded-lg p-6 gap-6 shadow">
                <div className="dark:text-gray-200 text-gray-700 flex flex-col justify-center items-center gap-6">
                    <div>
                        <span>نسخه : </span>
                        <span dir="ltr">{Config.version}</span>
                    </div>

                    <p>
                        (این قسمت در بروز رسانی آینده کامل تر خواهد شد)
                    </p>

                </div>
            </div>

            <div className="grid md:grid-cols-2 grid-cols-1 md:gap-8 gap-4 ">
                <ChartBlockShow label="تعداد کل کاربران" icon={<AiOutlineUser size={45}/>} value={chart.countUserAll} status={status}/>
                <ChartBlockShow label="کاربران جدید در 30 روز گذشته" icon={<AiOutlineUsergroupAdd size={45}/>} value={chart.countUserThisMouthRegister} status={status}/>
            </div>

            <div className="grid md:grid-cols-3 grid-cols-1 md:gap-8 gap-4 ">
                <ChartBlockShow label="آنلاینی 10 دقیقه گذشته" icon={<AiOutlineUserSwitch size={45}/>} value={chart.countUserInLogin10Min} status={status}/>
                <ChartBlockShow label="آنلاینی 24 ساعت گذشته" icon={<AiOutlineUserSwitch size={45}/>} value={chart.countUserInLogin24Clock} status={status}/>
                <ChartBlockShow label="آنلاینی 30 روز گذشته" icon={<AiOutlineUserSwitch size={45}/>} value={chart.countUserLoginThisMouth} status={status}/>
            </div>

            <div className="grid md:grid-cols-3 grid-cols-1 md:gap-8 gap-4 sm:mb-0 mb-12">
                <ChartBlockShow label="درخواست اتصال در 10 دقیقه" icon={<AiOutlineUserSwitch size={45}/>} value={chart.countUserRequestLogin10Min} status={status}/>
                <ChartBlockShow label="درخواست اتصال در 24 ساعت" icon={<AiOutlineUserSwitch size={45}/>} value={chart.countUserRequestLogin24Clock} status={status}/>
            </div>
        </div>
    )
        ;
};

export default Dashboard;
