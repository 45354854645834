import React, {useEffect} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getAsyncIp} from "../../features/ip/ipSlice";
import Error from "../../components/messageRedux/Error";
import Loading from "../../components/messageRedux/Loading";
import {BsCaretRightFill} from "react-icons/bs";

const IpDetails = () => {
    const {ip} = useParams()

    const navigate = useNavigate()

    const {ipDetails, loading, error} = useSelector(state => state.ip)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getAsyncIp({ip: ip}))
    }, [])

    const backHandler = () => {
        return navigate(-1)
    }

    return (
        <div className="flex flex-col gap-4">
            <div className="bg-white shadow dark:bg-[#1f2937] dark:shadow-none dark:border dark:border-gray-600 rounded-lg p-6  flex-col flex gap-y-6">

                <div onClick={backHandler} className="cursor-pointer dark:text-gray-200 text-gray-800 flex flex-row gap-1 items-center w-fit border border-gray-600 p-2 dark:hover:bg-gray-700 hover:bg-gray-300 rounded-lg">
                    <BsCaretRightFill size={20}/>
                    <span>برگشت به صفحه قبل</span>
                </div>

                <div><h2 className="font-bold text-gray-600 dark:text-gray-300">اطلاعات آیپی</h2></div>
                <div>
                    {loading && (<Loading/>)}
                    {error && (<Error error={error}/>)}
                    {!loading && error === null && (
                        <div className="flex flex-col space-y-3 dark:text-gray-300 text-gray-700 ">
                            <div className="grid grid-cols-5 text-base">
                                <div className="col-span-1">آیپی :</div>
                                <div className="col-span-4">{ipDetails.ipAddress}</div>
                            </div>
                            <div className="grid grid-cols-5 text-base">
                                <div className="col-span-1">ورژن آیپی :</div>
                                <div className="col-span-4">{ipDetails.ipVersion}</div>
                            </div>
                            <div className="grid grid-cols-5 text-base">
                                <div className="col-span-1">کشور :</div>
                                <div className="col-span-4">{ipDetails.countryName}</div>
                            </div>
                            <div className="grid grid-cols-5 text-base">
                                <div className="col-span-1">کد کشور :</div>
                                <div className="col-span-4">{ipDetails.countryCode}</div>
                            </div>
                            <div className="grid grid-cols-5 text-base">
                                <div className="col-span-1">شهر</div>
                                <div className="col-span-4">{ipDetails.cityName}</div>
                            </div>
                            <div className="grid grid-cols-5 text-base">
                                <div className="col-span-1">تایم زون</div>
                                <div className="col-span-4">{ipDetails.timeZone}</div>
                            </div>
                            <div className="grid grid-cols-5 text-base">
                                <div className="col-span-1">موقعیت مکانی</div>
                                <div className="col-span-4">{ipDetails.regionName}</div>
                            </div>

                        </div>
                    )}
                </div>
            </div>
        </div>
    )
        ;
};

export default IpDetails;
