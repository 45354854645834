import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {decrements, increments} from "../features/counter/counterSlice";


const Counter = () => {

    const [value ,setValue] = useState(0)
    const counter = useSelector(state => state.counter)
    const dispatch = useDispatch()
     return (
        <div className="flex flex-col border p-4 text-center text-white gap-6">
            <p>{counter.value}</p>
            <input className="text-green-500 p-2" type="text" value={value} onChange={(e)=>setValue(e.target.value)}/>
            <button onClick={()=>{dispatch(increments(value | 0))}} className="bg-indigo-500 rounded hover:bg-indigo-600 py-2">افزودن</button>
            <button onClick={()=>{dispatch(decrements(value | 0))}}
                    className="bg-indigo-500 rounded hover:bg-indigo-600 py-2">کاهش</button>
        </div>
    );
};

export default Counter;
