import {createSlice,createAsyncThunk} from '@reduxjs/toolkit'
import axios from "axios";
import {Config, ConfigMessage} from "../../config/Config";
import {JsonToWWWFormData} from "../../utility/JsonToWWWFormData";


export const getAsyncChart = createAsyncThunk("chart/getAsyncChart",async (_,{rejectWithValue})=>{
    try {
        const res = await axios.post(Config.api,JsonToWWWFormData({action : "chart"}),
            {
                headers: {'Authorization': 'Bearer ' + sessionStorage.getItem('token')}
            })
        const value = res.data
        return await value;
    }catch (error){
        return rejectWithValue([],error.message)
    }
})

const initialState = {
    chart: {},
    error : null,
    loading : false,
    status : "idle"
}

const chartSlice = createSlice({
    name : 'chart',
    initialState,
    reducers :{
    },
    extraReducers : {
        [getAsyncChart.fulfilled] : (state,action)=>{
            if(action.payload.status === "success"){
                return {...state,chart :action.payload.result, status : 'success' ,loading: false , error: null}
            }else {
                return {...state,chart : [] ,status : 'error' ,loading: false , error: action.payload.message}
            }
        },
        [getAsyncChart.pending] : (state,action)=>{
            return {...state, chart : {} , status : 'pending',loading: true , error: null}
        },
        [getAsyncChart.rejected] : (state,action)=>{
            return {...state, chart :{} ,status : 'error',loading: false , error: ConfigMessage.error}
        }
    }
})

export const {} = chartSlice.actions
export default chartSlice.reducer
