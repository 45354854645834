import React from 'react';

const Paging = ({page,setNumberPage,numberPage}) => {
    let item = [];
    if (page === 1) {

    } else if (page <= 5) {

        for (let i = 1; i <= page; i++) {
            item.push(<PageItem setNumberPage={setNumberPage} numberPage={numberPage} num={i}/>)
        }

    } else {

        if(numberPage - 6 > 0){
            for (let i = 1; i <= 2; i++) {
                item.push(<PageItem setNumberPage={setNumberPage} numberPage={numberPage} num={i}/>)
            }
            if(numberPage - 7 > 0){
                item.push(<PageItem num="..."/>)
            }
        }

        for (let i = numberPage-4 <= 0 ? 1 : numberPage - 4; i <= (numberPage + 3 >=page ? page : numberPage + 3); i++) {
            item.push(<PageItem setNumberPage={setNumberPage} numberPage={numberPage} num={i}/>)
        }
        if(numberPage + 4 < page -1){
            item.push(<PageItem num="..."/>)
            for (let i = page-1; i <= page; i++) {
                item.push(<PageItem setNumberPage={setNumberPage} numberPage={numberPage} num={i}/>)
            }
        }

    }
    return (
        <ul className="inline-flex items-center -space-x-px">
            {item}
        </ul>
    )
}

export default Paging;


const PageItem = ({num,setNumberPage,numberPage}) => {
    return (
        <li>
            <div onClick={()=>setNumberPage(num)}  className={`${numberPage === num ? "dark:bg-gray-600 bg-gray-300" : "dark:bg-gray-800 bg-white"} cursor-pointer py-2 px-3 leading-tight text-gray-500 
             border border-gray-300 hover:bg-gray-100 hover:text-gray-700 
              dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white`}>
                {num}
            </div>
        </li>
    )
}
