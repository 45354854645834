import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import persianDate from "persian-date";
import {useDispatch, useSelector} from "react-redux";
import {getAsyncUsers, postAsyncChangeStatusUser} from "../../features/user/userSlice";
import PageTitle from "../../components/ui/PageTitle";
import Loading from "../../components/messageRedux/Loading";
import Error from "../../components/messageRedux/Error";
import Show from "../../components/table/Show";
import DataTable from "../../components/dataTable/DataTable";

const Users = () => {

    const {users,count,page,loading, error} = useSelector(state => state.user)
    const dispatch = useDispatch()
    const [perPage,setPerPage] = useState(30)
    const [numberPage,setNumberPage] = useState(1)

    useEffect(() => {
        dispatch(getAsyncUsers({row_per_page: parseInt(perPage), page_number: parseInt(numberPage)}))
    }, [perPage,numberPage])

    const columns = [
        {
            name: "کاربر",
            selector: row => row.device_id,
        },
        {
            name: "دستگاه",
            selector: row => row.device_name,
        },
        {
            name: "تاریخ آخرین بازدید",
            selector: row => new persianDate.unix(row.user_last_login_time).format("HH-mm-ss YYYY-MM-DD"),
        },
        {
            name: "تاریخ ایجاد",
            selector: row => new persianDate.unix(row.user_register_time).format("HH-mm-ss YYYY-MM-DD"),
        },
        {
            name: "ورژن برنامه",
            selector: row => (<span dir='ltr'>{row.app_version_name}</span>),
        },
        {
            name: "عملیات",
            width: '150px',
            selector: row => (
                <div className="flex flex row items-center gap-2">
                    <Link to={'/user/' + row.user_id} className="btn-blue">
                        <Show/>
                    </Link>
                    {row.user_status === 'active' ? (
                        <button onClick={() => dispatch(postAsyncChangeStatusUser({id: row.user_id}))} className={`btn-red`}>
                             <span className=' text-white text-xs lg:text-sm'>بلاک کردن</span>
                        </button>
                    ) : (
                        <button onClick={() => dispatch(postAsyncChangeStatusUser({id: row.user_id}))} className={`btn-green`}>
                             <span className=' text-white text-xs lg:text-sm'>فعال کردن</span>
                        </button>
                    )}

                </div>
            )
        }
    ];


    return (
        <div className="flex flex-col gap-4">
            <div className="bg-white shadow dark:bg-[#1f2937] dark:shadow-none dark:border dark:border-gray-600 rounded-lg p-6  flex-col flex gap-y-6">
                <PageTitle label="کاربران"/>
                <div>
                    {loading && (<Loading/>)}
                    {error && (<Error error={error}/>)}
                    {!loading && error === null && (
                        <DataTable
                            columns={columns}
                            data={users}
                            count={count}
                            page={page}
                            perPage={perPage}
                            setPerPage={setPerPage}
                            numberPage={numberPage}
                            setNumberPage={setNumberPage}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default Users;
