import React, {useEffect, useState} from 'react';
import persianDate from "persian-date";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {deleteAsyncConfig, getAsyncConfigs} from "../../features/config/configSlice";
import {connectUpDown} from "../../utility/connectStatus";
import Loading from "../messageRedux/Loading";
import Error from "../messageRedux/Error";
import Edit from "../table/Edit";
import Trash from "../table/Trash";
import DataTable from "../dataTable/DataTable";

const ConfigList = ({label}) => {


    const {configs,count ,page , loading , error} = useSelector(state => state.config)
    const dispatch = useDispatch()
    const [perPage,setPerPage] = useState(30)
    const [numberPage,setNumberPage] = useState(1)

    useEffect(()=>{
        console.log(label)
        dispatch(getAsyncConfigs({protocol : label,row_per_page: parseInt(perPage), page_number: parseInt(numberPage)}))
    },[perPage,numberPage,label])


    const column = [
        {
            name: "توضیح",
            selector: row => row.comment,
        },
        {
            name: "آیپی سرور",
            selector: row => row.vpn_server_ip,
        },
        {
            name: "نام انگلیسی سرور",
            selector: row => (<span dir='ltr'>{row.vpn_server_en_title}</span>),
        },
        {
            name: "تاریخ افزودن",
            selector: row => new persianDate.unix(row.vpn_server_creation_time).format("HH-mm-ss YYYY-MM-DD"),
        },
        {
            name: "وضعیت",
            maxWidth: '160px',
            selector: row => (<span className="text-green-500 p-3 rounded-lg overflow-hidden">
                {connectUpDown[row.vpn_server_status]}
            </span>),
        },
        {
            name: "عملیات",
            maxWidth: '120px',
            selector: row => (
                <div className="flex flex row items-center gap-2">
                    <Link to={`/config/edit/${row.vpn_server_id}`} className="btn-green">
                        <Edit/>
                    </Link>
                    <button onClick={()=>dispatch(deleteAsyncConfig({id : row.vpn_server_id}))} className="btn-red">
                        <Trash/>
                    </button>
                </div>
            )
        }
    ];

    return (
        <div className="flex flex-col gap-y-7">
            <div>
                <h2 className="font-bold text-gray-600 dark:text-gray-300 ">
                    <span>لیست همه سرور های : </span>
                    <span>{label}</span>
                </h2>
            </div>
            {loading && (<Loading/>)}
            {error && (<Error error={error}/>)}
            {!loading && error === null && (
                <DataTable
                    columns={column}
                    data={configs}
                    count={count}
                    page={page}
                    perPage={perPage}
                    setPerPage={setPerPage}
                    numberPage={numberPage}
                    setNumberPage={setNumberPage}
                />
            )}

        </div>
    );
};

export default ConfigList;
