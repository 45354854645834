import React from 'react';
import {BiLoaderAlt} from "react-icons/bi";

const Loading = () => {
    return (
        <span className="dark:text-gray-300 text-gray-700
         flex flex-col gap-4 justify-center items-center mt-10 text-lg ">
            <div className="animate-spin">
                <BiLoaderAlt size={50}/>
            </div>
            در حال دریافت اطلاعات
        </span>
    );
};

export default Loading;
