import {createSlice,createAsyncThunk} from '@reduxjs/toolkit'
import axios from "axios";
import {Config, ConfigMessage} from "../../config/Config";
import {JsonToWWWFormData} from "../../utility/JsonToWWWFormData";

//list
export const getAsyncConnectionType = createAsyncThunk("connectionType/getAsyncConnectionType",async (_,{rejectWithValue})=>{
    try {
        const res = await axios.post(Config.api,JsonToWWWFormData({action: "connection-type"}),
            {
                headers: {'Authorization': 'Bearer ' + sessionStorage.getItem('token')}
            })
        const value = res.data.result
        return await value;
    }catch (error){
        return rejectWithValue([],error.message)
    }
})

const initialState = {
    connectionType : "",
    error : null,
    loading : false
}

const connectionTypeSlice = createSlice({
    name : 'connectionType',
    initialState,
    reducers :{

    },
    extraReducers : {
        [getAsyncConnectionType.fulfilled] : (state,action)=>{
            return {...state,connectionType : action.payload.connectionType ,loading: false , error: null}
        },
        [getAsyncConnectionType.pending] : (state,action)=>{
            return {...state,connectionType : "" ,loading: true , error: null}
        },
        [getAsyncConnectionType.rejected] : (state,action)=>{
            return {...state,connectionType : "" ,loading: false , error: ConfigMessage.error}
        }
    }
})

export const { } = connectionTypeSlice.actions
export default connectionTypeSlice.reducer
