import {BiCheck, BiX} from "react-icons/bi";

export const connectStatus = {
    true : <BiCheck className="bg-green-500 text-white rounded-full" size={26}/>,
    false : <BiX className="bg-red-500 text-white rounded-full"  size={26}/>
}

export const connectUpDown = {
    up : <BiCheck className="bg-green-500 text-white rounded-full" size={26}/>,
    down : <BiX className="bg-red-500 text-white rounded-full"  size={26}/>
}
