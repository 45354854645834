import React, {useEffect, useState} from 'react';

const RadioButtonModern = ({formik,name,label,list,defaultValue = false}) => {

    const [cols ,setCols] = useState('grid-cols-4')
    const [selected, setSelected] = useState("");

    useEffect(()=>{

        if(list.length === 3){
            setCols('grid-cols-3')
        }

    },[defaultValue])


    // {defaultValue ? defaultValue === item.value : formik.values[name] === item.value}
    return (
       <div className="flex flex-col ">
           <label className="mb-3 text-base dark:text-gray-300">
               {label}
           </label>
           <div className={`grid ${cols} bg-gray-200 rounded-lg p-2 dark:bg-[#1f2937]`}>
               {list && list.map((item)=>{
                   return (
                       <div key={item.id}>
                           <input key={item.id} {...formik.getFieldProps(name)} type="radio" name={name} id={item.label} value={item.value}  checked={formik.values[name] === item.value}  className="peer hidden"/>
                           <label htmlFor={item.label}  className="dark:text-white text-xs md:text-base block cursor-pointer select-none rounded-xl p-2 text-center peer-checked:bg-indigo-500 peer-checked:font-bold peer-checked:text-white">
                               {item.label}
                           </label>
                       </div>
                   )
               })}
           </div>
       </div>
    );
};

export default RadioButtonModern;
