import React from 'react';
import {Config} from "../../../config/Config";
import {FiX} from "react-icons/fi";
import SidebarItem from "./SidebarItem";
import {UserSlider} from "../../../assets/data/Data";
import SidebarItemWhitoutSub from "./SidebarItemWhitoutSub";

const Sidebar = ({sidebarHandler, mobileClass, isMobile}) => {

    return (
        <>
            <div id="sidebar" className={`${mobileClass} dark:bg-[#111827] overflow-hidden`}>
                <div className="flex flex-col px-4 py-5 ">
                    <div className="mb-3  flex inline-flex  items-center">
                        <div className="flex flex-row justify-center items-center">
                            <img className="w-16" src={Config.logo} alt=""/>
                            <div className="mb-4 overflow-hidden w-36 mt-4">
                                <span className="text-indigo-500 font-bold text-xl mr-2 fontEn">{Config.panelTitle}</span>
                            </div>
                        </div>
                        {isMobile && <FiX onClick={sidebarHandler} className="cursor-pointer dark:text-gray-300 text-gray-700" size={20}/>}
                    </div>

                    {UserSlider && UserSlider.map((item) => {
                        if (item.sub.length > 0) {
                            return (
                                <SidebarItem key={item.id} id={item.id} icon={item.icon} sub={item.sub} title={item.title}/>
                            )
                        } else {
                            return (
                                <SidebarItemWhitoutSub key={item.id} id={item.id} icon={item.icon} link={item.link} title={item.title}/>
                            )
                        }
                    })}

                </div>
            </div>
            {isMobile &&
                <button onClick={sidebarHandler} className="xl:hidden bg-black opacity-60 absolute inset-0 z-30 "></button>
            }
        </>
    );
};

export default Sidebar;
