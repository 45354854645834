import React,{useState} from 'react';
import {FiChevronLeft, FiCircle} from "react-icons/fi";
import {NavLink} from "react-router-dom";


const SidebarItem = ({id,title,icon,sub}) => {
    const [open, setOpen] = useState("0")
    const OpenMenuHandler = (id) => {
        open === '0' ? setOpen(id) : id === open ? setOpen('0') : setOpen(id)
    }

    const classActive = 'cursor-pointer flex flex-row items-center pr-4 py-3 pl-1 rounded-lg bg-indigo-500 dark:bg-indigo-600 text-white '
    const classInactive = 'cursor-pointer flex flex-row items-center pr-4 py-3 pl-1 rounded-lg text-gray-500 dark:text-gray-300'

    return (
        <div className="">
            <button id={id} onClick={()=>OpenMenuHandler(id)} className={`${open === id ? 'bg-gray-100 dark:bg-gray-800 ' : ''} z-40 w-full cursor-pointer flex flex-row justify-between items-center pr-3 py-3 pl-1 rounded-lg`}>
                <div className="inline-flex justify-center items-center text-gray-600 dark:text-gray-200 ">
                    {icon}
                     <span className="mr-2 text-gray-600 dark:text-gray-300">{title}</span>
                </div>
                <FiChevronLeft className={`${open !== id ? "" : "-rotate-90"} transition-all duration-500 text-gray-600 dark:text-gray-300`}/>
            </button>
            <div className={`${open !== id ? 'max-h-0' : 'max-h-52'} transition-all ease-in-out duration-500 mt-1 flex flex-col overflow-hidden`}>
                {sub && sub.map((item)=>{
                    return (
                        <NavLink to={item.link} key={item.id} end className={(navData) => (navData.isActive ? classActive : classInactive)}>
                            <FiCircle className="" size={11}/>
                            <span className="mr-2  ">{item.title}</span>
                        </NavLink>
                    )
                })}
            </div>
        </div>
    );
};

export default SidebarItem;
