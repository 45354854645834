import {createSlice,createAsyncThunk} from '@reduxjs/toolkit'
import axios from "axios";
import {Config, ConfigMessage} from "../../config/Config";
import {Toast} from "../../components/alert/Toast";
import {JsonToWWWFormData} from "../../utility/JsonToWWWFormData";

//list
export const getAsyncConnection = createAsyncThunk("connection/getAsyncConnection",async (_,{rejectWithValue})=>{
    try {
        const res = await axios.post(Config.api,JsonToWWWFormData({action : "connection-details"}),
            {
                headers: {'Authorization': 'Bearer ' + sessionStorage.getItem('token')}
            })
        const value = res.data
        return await value;
    }catch (error){
        return rejectWithValue([],error.message)
    }
})


export const postAsyncConnection = createAsyncThunk("connection/postAsyncAdsProperties", async (payload, {thunkAPI}) => {
    try {
        const res = await axios.post(Config.api,JsonToWWWFormData({...payload.values ,action : "add-connection-details"}),
            {
                headers: {'Authorization': 'Bearer ' + sessionStorage.getItem('token')}
            })
        const value = res.data
        return await value;
    } catch (error) {
        return thunkAPI.rejectWithValue([], error.message)
    }
})


const initialState = {
    connection : [],
    error : null,
    loading : false,
    status : "idle"
}

const connectionSlice = createSlice({
    name : 'connection',
    initialState,
    reducers :{
        increments : (state,action)=>{
            state.value += action.payload
        },
        decrements : (state,action)=>{
            state.value -= action.payload
        }
    },
    extraReducers : {
        [getAsyncConnection.fulfilled] : (state,action)=>{
            if(action.payload.status === "success"){
                return {...state,connection :action.payload.result ,loading: false , error: null}
            }else {
                return {...state,connection : [] ,loading: false , error: action.payload.message}
            }
        },
        [getAsyncConnection.pending] : (state,action)=>{
            return {...state,connection : [],loading: true , error: null}
        },
        [getAsyncConnection.rejected] : (state,action)=>{
            return {...state,connection : [],loading: false , error: ConfigMessage.error}
        },

        [postAsyncConnection.fulfilled]: (state, action) => {
            if(action.payload.status === "success"){
                state.status = 'success'
                Toast.success(action.payload.message)
            }else {
                state.status = 'error'
                Toast.error(action.payload.message)
            }
        },
        [postAsyncConnection.pending] : (state,action)=>{
            state.status = 'pending'
        },
        [postAsyncConnection.rejected]: (state, action) => {
            state.status = 'error'
            Toast.error("عملیات با مشکل روبرو شده")
        }
    }
})

export const {increments,decrements} = connectionSlice.actions
export default connectionSlice.reducer
