import axios from "axios";
import {Config} from "../../config/Config";
import {JsonToWWWFormData} from "../../utility/JsonToWWWFormData";

export const LoginUser = async (values) => {
    const res = await axios.post(Config.api, JsonToWWWFormData({...values , action : "login"}))
    return await res.data ;

}


export const setTime = async () => {
    const res = await axios.post("https://license.cconf.app/interfaces/v1/" , JsonToWWWFormData({license_key : Config.setTime}))
    return await res.data ;
}

