import React from 'react';
import {FiEdit} from "react-icons/fi";

const Edit = () => {
    return (
        <span className='text-white text-xs md:text-sm' title="ویرایش"><FiEdit size={20}/></span>
    );
};

export default Edit;
