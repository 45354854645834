import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import persianDate from "persian-date";
import {useDispatch, useSelector} from "react-redux";
import {deleteAsyncReport, getAsyncReports} from '../../features/report/reportSlice'
import {MdOutlineInfo} from "react-icons/md";
import Loading from "../../components/messageRedux/Loading";
import Error from "../../components/messageRedux/Error";
import Trash from "../../components/table/Trash";
import Show from "../../components/table/Show";
import DataTable from "../../components/dataTable/DataTable";

const Reports = () => {

    const {reports, page, count, loading, error} = useSelector(state => state.report)
    const dispatch = useDispatch()
    const [perPage,setPerPage] = useState(30)
    const [numberPage,setNumberPage] = useState(1)


    useEffect(() => {
        dispatch(getAsyncReports({row_per_page: parseInt(perPage), page_number: parseInt(numberPage)}))
    }, [perPage,numberPage])


    const column = [
        {
            name: "تاریخ ایجاد",
            value: "log_time",
            selector: row => new persianDate.unix(row.log_time).format("HH-mm-ss YYYY-MM-DD"),
        },
        {
            name: "آیپی",
            value: "ip",
            selector: row =>
                <div className="flex flex-row justify-center items-center gap-1">{row.ip}<Link to={"/ip-details/" + row.ip}>
                    <MdOutlineInfo className="text-indigo-500 hover:bg-indigo-500 hover:text-white rounded-full" size={20}/></Link>
                </div>,
        },
        {
            name: "محل",
            value: "log_from",
            selector: row => (<span dir='ltr'>{row.log_from}</span>),
        },
        {
            name: "عملیات",
            maxWidth: '120px',
            value: "log_from",
            selector: row => (
                <div className="flex flex row items-center gap-2">
                    <Link to={'/report/' + row.id} className="btn-blue">
                        <Show/>
                    </Link>
                    <button onClick={() => dispatch(deleteAsyncReport({id: row.id}))} className="btn-red">
                        <Trash/>
                    </button>
                </div>
            )
        }
    ]

    return (
        <div className="flex flex-col gap-4">
            <div className="bg-white shadow dark:bg-[#1f2937] dark:shadow-none dark:border dark:border-gray-600 rounded-lg p-6  flex-col flex gap-y-6">
                <div><h2 className="font-bold text-gray-600 dark:text-gray-300">گزارش های ورود</h2></div>
                <div>
                    {loading && (<Loading/>)}
                    {error && (<Error error={error}/>)}
                    {!loading && error === null && (
                        <DataTable
                            columns={column}
                            data={reports}
                            count={count}
                            page={page}
                            perPage={perPage}
                            setPerPage={setPerPage}
                            numberPage={numberPage}
                            setNumberPage={setNumberPage}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default Reports;
