import React, {useEffect, useState} from 'react';
import persianDate from "persian-date";
import {useDispatch, useSelector} from "react-redux";
import {getAsyncMetaData} from "../../features/metaData/metaDataSlice";
import {Link} from "react-router-dom";
import {MdOutlineInfo} from "react-icons/md";
import PageTitle from "../ui/PageTitle";
import Loading from "../messageRedux/Loading";
import Error from "../messageRedux/Error";
import DataTable from "../dataTable/DataTable";

const MetaData = ({id}) => {

    const {metaData,count,page, loading, error} = useSelector(state => state.metaData)
    const dispatch = useDispatch()
    const [perPage,setPerPage] = useState(30)
    const [numberPage,setNumberPage] = useState(1)

    useEffect(() => {
        dispatch(getAsyncMetaData({id: id,row_per_page: parseInt(perPage), page_number: parseInt(numberPage)}))
    }, [perPage,numberPage])


    const column = [
        {
            name: "نوع متا",
            selector: row => row.meta_type,
        },
        {
            name: "اطلاعات متا",
            selector: row => row.meta_info,
        },
        {
            name: "آیپی یوزر",
            selector: row => <div className="flex flex-row justify-center items-center gap-1">{row.user_ip}<Link to={"/ip-details/" + row.user_ip} >
                <MdOutlineInfo className="text-indigo-500 hover:bg-indigo-500 hover:text-white rounded-full" size={20}/></Link>
            </div>,
        },
        {
            name: "اطلاعات اضافه",
            selector: row => row.user_agent,
        },
        {
            name: "تاریخ آخرین بازدید",
            selector: row => new persianDate.unix(row.meta_create_time).format("HH-mm-ss YYYY-MM-DD"),
        }

    ];

    return (
        <div className="flex flex-col">
            <PageTitle label="رفتار کاربر"/>
            {loading && (<Loading/>)}
            {error && (<Error error={error}/>)}
            {!loading && error === null && (
                <DataTable
                    columns={column}
                    data={metaData}
                    count={count}
                    page={page}
                    perPage={perPage}
                    setPerPage={setPerPage}
                    numberPage={numberPage}
                    setNumberPage={setNumberPage}
                />
            )}
        </div>
    );
};

export default MetaData;


