import React from 'react';
import {useFormik} from "formik";
import Input from "../../components/input/Input";
import Button from "../../components/button/Button";
import * as Yup from "yup";
import {RegExp} from "../../config/Config";

const ChangePassword = () => {

    const initialValues = {
        old_password: "",
        new_password: "",
        confirm_new_password: ""
    }

    const onSubmit = () => {

    }

    const validationSchema = Yup.object({
        old_password: Yup.string().min(8,'حداقل 8 رقم وارد کنید').max(8,'حداکثر 8 رقم وارد کنید'),
        new_password: Yup.string().min(8,'حداقل 8 رقم وارد کنید').max(8,'حداکثر 8 رقم وارد کنید'),
        confirm_new_password: Yup.string().oneOf([Yup.ref('new_password'), null], 'تکرار رمزعبور و رمز عبور با هم مطابقت ندارد').min(8,'حداقل 8 رقم وارد کنید').max(8,'حداکثر 8 رقم وارد کنید')

    })

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit
    })

    return (
        <div className="w-full bg-white p-8 rounded-lg shadow ">
            <form action="" onSubmit={formik.handleSubmit}>
               <div className="border p-4 rounded-lg">
                   <Input formik={formik} name="old_password" label="رمز قبلی"/>
               </div>
                <hr className="my-5"/>
                <div className="border p-4 rounded-lg mb-6">
                    <Input formik={formik} name="new_password" label="رمز جدید"/>
                    <Input formik={formik} name="confirm_new_password" label="تکرار رمز جدید"/>
                </div>
                <Button formik={formik} name="ثبت تغییرات"/>
            </form>
        </div>
    );
};

export default ChangePassword;
