import React from 'react';
import {FiTrash2} from "react-icons/fi";

const Trash = () => {
    return (
        <span className=' text-white text-xs md:text-sm' title="حذف"><FiTrash2 size={20}/></span>
    );
};

export default Trash;
