import React, {useEffect} from 'react';
import {useFormik} from "formik";
import * as Yup from "yup";
import {useDispatch, useSelector} from "react-redux";
import {getAsyncRegions} from "../../features/regions/regionsSlice";
import {useParams} from "react-router-dom";
import {clearConfig, editAsyncAdsConfig, getAsyncConfig,} from "../../features/config/configSlice";
import ConfigForm from "../../features/config/ConfigForm";

const AddConfig = () => {

    const {id} = useParams()

    const {config,status,error,loading} = useSelector(state => state.config)
    const {regions} = useSelector(state => state.regions)
    const dispatch = useDispatch()

    useEffect(()=>{
        dispatch(clearConfig())
        dispatch(getAsyncRegions())
        if(id && id > 0){
            dispatch(getAsyncConfig({id : id}))
        }
    },[id])


    const validationSchema = Yup.object({
        comment: Yup.string().required("کامنت ضروری است"),
        group_fa_name: Yup.string().required("نام گروه فارسی ضروری است"),
        group_en_name: Yup.string().required("نام گروه انگیسی ضروری است"),
        vpn_server_fa_title: Yup.string().required("نام فارسی ضروری است"),
        vpn_server_en_title: Yup.string().required("نام انگلیسی ضروری است"),
        vpn_server_ip: Yup.string().required("آیپی ضروری است"),
        vpn_server_users_count: Yup.string().required("ظرفیت سرور ضروری است")
    })

    const onSubmit = (values) => {
        dispatch(editAsyncAdsConfig({values : values}))
    }

    const formik = useFormik({
        initialValues : config ,
        validationSchema,
        validateOnMount: true,
        enableReinitialize : true,
        onSubmit,
    })




    return (
        <div className="bg-white shadow dark:bg-[#1f2937] dark:shadow-none dark:border dark:border-gray-600 flex flex-col bg-white rounded-lg p-6 gap-6 mb-32">
            <div><h2 className="font-bold text-gray-600 dark:text-gray-300">افزودن کافیگ جدید</h2></div>
            <ConfigForm status={status} formik={formik} regions={regions} defaultValue={false}/>
        </div>
    );
};

export default AddConfig;
