import React, {useEffect, useState} from 'react';
import SelectOption from "../../components/input/SelectOption";
import {select_protocol} from "../../assets/data/Data";
import {useFormik} from "formik";
import Input from "../../components/input/Input";
import Button from "../../components/button/Button";
import {useDispatch, useSelector} from "react-redux";
import {getAsyncConnection, postAsyncConnection} from "../../features/setting/connectionSlice";
import PageTitle from "../../components/ui/PageTitle";
import RadioButtonModernStatusTwo from "../../components/input/RadioButtonModernStatusTwo";
import {AiFillQuestionCircle} from "react-icons/ai";
import Loading from "../../components/messageRedux/Loading";
import Error from "../../components/messageRedux/Error";


const Connection = () => {

    const {connection,error,loading,status} = useSelector(state => state.connection)
    const dispatch = useDispatch()


    const [data,setData] = useState([])
    useEffect(()=>{
        dispatch(getAsyncConnection())
    },[])


    const onSubmit = (values) => {
        if(status !== 'pending'){
            dispatch(postAsyncConnection({values : values}))
        }
    }


    const formik = useFormik({
        initialValues : connection,
         validateOnMount: true,
        enableReinitialize : true,
        onSubmit,
    })



    return (
        <div className="flex flex-col bg-white dark:bg-[#1f2937] dark:shadow-none dark:border dark:border-gray-600 rounded-lg p-6 gap-6 mb-32 shadow">
            <PageTitle label="کانکشن"/>

            {loading && (<Loading/>)}
            {error && (<Error error={error}/>)}
            {!loading && error === null && (

                <form className="flex flex-col gap-9" onSubmit={formik.handleSubmit}>
                    <div className="flex flex-col gap-y-4">
                        <div>
                            <h2 className="font-bold text-gray-600 dark:text-gray-300">تنظیمات انتخاب سرور هوشمند :</h2>
                        </div>
                        <div className="grid md:grid-cols-2 grid-cols-1 gap-x-4">
                            <Input formik={formik} name="MIN_RANDOM_SERVER_ID" label="انتخاب هوشمند سرور از سرور"/>
                            <Input formik={formik} name="MAX_RANDOM_SERVER_ID" label="تا سرور"/>
                        </div>
                    </div>
                    <hr className="dark:border-gray-400" />
                    <div className="flex flex-col gap-y-4">
                        <div>
                            <h2 className="font-bold text-gray-600 dark:text-gray-300">تنظیمات دی ان اس ها :</h2>
                        </div>
                        <div className="grid md:grid-cols-2 grid-cols-1 gap-x-4">
                            <Input formik={formik} name="DEFAULT_FIRST_DNS" label="دی ان اس 1"/>
                            <Input formik={formik} name="DEFAULT_SECOND_DNS" label="دی ان اس 2"/>
                        </div>
                    </div>
                    <hr className="dark:border-gray-400" />
                    <div className="flex flex-col gap-y-4">
                        <div>
                            <h2 className="font-bold text-gray-600 dark:text-gray-300">تنظیمات پروتکل ها :</h2>
                        </div>
                        <div className="grid md:grid-cols-2 grid-cols-1 gap-x-4">
                            <SelectOption formik={formik} name="DEFAULT_PROTOCOL" label="پروتکل پیش فرض" list={select_protocol}/>
                        </div>
                    </div>
                    <hr className="dark:border-gray-400" />
                    <div className="flex flex-col gap-y-4">
                        <div>
                            <h2 className="font-bold text-gray-600 dark:text-gray-300">تنظیمات انتخاب سرور :</h2>
                        </div>
                        <div className="grid md:grid-cols-2 grid-cols-1 gap-x-4">
                            <Input formik={formik} name="DEFAULT_SERVER_SELECTION" label="تغییر سرور"/>
                        </div>
                    </div>
                    <hr className="dark:border-gray-400" />
                    <div className="flex flex-col gap-y-4">
                        <div>
                            <h2 className="font-bold text-gray-600 dark:text-gray-300">تلاش برای اتصال :</h2>
                        </div>
                        <RadioButtonModernStatusTwo  formik={formik} label="تعویض خودکار سرور"  name="CHANGE_SERVER_ON_TIMEOUT_ENABLED" disable="false" enable="true"/>
                        <div className="flex flex-row text-xs gap-2 dark:text-gray-500 text-gray-600 items-center ">
                            <AiFillQuestionCircle size={25}/>
                            <span>با روشن بودن این قابلیت در صورت عدم اتصال به سرور - سرور خودکار تعویض می شود</span>
                        </div>
                        <div className="grid md:grid-cols-2 grid-cols-1 gap-x-4">
                            <Input formik={formik} name="TRY_TO_CONNECT_TIMEOUT" label="زمان تلاش برای اتصال"/>
                            <Input formik={formik} name="TRY_TO_RECONNECT_SERVER" label="تعداد دفعات تلاش برای اتصال"/>
                        </div>
                    </div>


                    <div className="mt-8">
                        <Button formik={formik} name="ثبت" status={status}/>
                    </div>
                </form>
            )}

        </div>
    );
};

export default Connection;
