import {
    FiHome,
    FiPieChart,
    FiSettings,
    FiUsers
} from "react-icons/fi";
import {BsShieldPlus} from "react-icons/bs";
import {HiOutlineSpeakerphone} from "react-icons/hi";
import {FaPowerOff} from "react-icons/fa";
import {BiNews} from "react-icons/bi";
import {MdOutlineHelpOutline} from "react-icons/md";


export const UserSlider = [
    {
        id: "1",
        title: "پیشخوان",
        icon: <FiHome size={20}/>,
        link:"/dashboard",
        sub: []
    },
    {
        id: "2",
        title: "گزارشات",
        icon: <FiPieChart size={20}/>,
        link:"/report",
        sub: []
    },
    {
        id: "3",
        title: "کاربران",
        icon: <FiUsers size={20}/>,
        link:"/user",
        sub: []
    },
    {
        id: "4",
        title: "گزارش کاربران",
        icon: <BiNews size={20}/>,
        link:"/contact",
        sub: []
    },
    {
        id: "5",
        title: "کانفیگ",
        icon: <BsShieldPlus size={20}/>,
        sub: [
            {id: "21", title: "افزودن", link:"/config/add"},
            {id: "22", title: "لیست کانفیگ ها", link:"/config"}
        ]
    },
    {
        id: "6",
        title: "سرویس های تبلیغاتی",
        icon: <HiOutlineSpeakerphone size={20}/>,
        link:"/ads",
        sub: []
    },
    {
        id: "7",
        title: "تنظیمات نرم افزار",
        icon: <FiSettings size={20}/>,
        sub: [
            {id: "41", title: "تبلیغات", link:"/set-application/ads-setting"},
            {id: "42", title:  "کانکشن" , link:"/set-application/connection"},
            {id: "43", title:  "اپلیکیشن های بلاک شده" , link:"/set-application/app-block"},
            {id: "44", title:  "نسخه" , link:"/set-application/version"},

        ]
    },
    {
        id: "8",
        title: "راهنما",
        icon: <MdOutlineHelpOutline size={20}/>,
        link:"/about",
        sub: []
    },

]




export const server_signal = [
    {id : "1" , value : "a" , text : "عالی"},
    {id : "2" , value : "b" , text : "خوب"},
    {id : "3" , value : "c" , text : "متوسط"},
    {id : "4" , value : "d" , text : "ضعیف"},
    {id : "5" , value : "e" , text : "افتضاح"}
]

export const kindShow = [
    {id : "1" , value : "enable" , text : "همیشه"},
    {id : "2" , value : "random" , text : "اتوماتیک"},
]

export const vpn_server_protocol = [
    {id : "1" , label : "OpenVPN" , value : "openvpn" , checked : true},
    {id : "2" , label : "IKev2" , value : "ikev2", checked : false},
    {id : "3" , label : "Cisco" , value : "cisco", checked : false},
    {id : "4" , label : "V2ray" , value : "v2ray", checked : false}
]

export const select_protocol = [
    {id : "1" , value : "openvpn" , text : "OpenVPN"},
    {id : "2" , value : "ikev2" , text : "IKev2"},
    {id : "3" , value : "cisco" , text : "Cisco"},
    {id : "4" , value : "v2ray" , text : "V2ray"}
]

export const kindDialog = [
    {id : "1" , label : "دیالوگ شخصی" , value : "OpenVPN"},
    {id : "2" , label : "نظر سنجی گوگل" , value : "IKev2"},
    {id : "3" , label : "عدم نمایش" , value : "Cisco"},
]


export const vpn_server_status = [
    {id : "1" , value : "up" , text : "فعال"},
    {id : "2" , value : "down" , text : "غیر فعال"},
]


export const threeStatus = [
    {id : "1" , value : "enable" , text : "فعال"},
    {id : "2" , value : "random" , text : "هوشمند"},
    {id : "3" , value : "disable" , text : "غیر فعال"},
]

export const twoStatus = [
    {id : "1" , value : "enable" , text : "فعال"},
    {id : "2" , value : "random" , text : "غیر فعال"},
]

export const TrueFalse = [
    {id : "1" , value : "False" , text : "غیر فعال"},
    {id : "2" , value : "True" , text : "فعال"},
]

export const trueFalse = [
    {id : "1" , value : false , text : "غیر فعال"},
    {id : "2" , value : true , text : "فعال"},
]

export const selectStatus = [
    {id : "1" , label : "فعال" , value : "enable" ,icon :  <FaPowerOff size={22}/> , checked : false},
    {id : "2" , label : "هوشمند" , value : "random",icon :  <FaPowerOff size={22}/> , checked : false},
    {id : "3" , label : "غیر فعال" , value : "disable",icon :  <FaPowerOff size={22}/> , checked : true},
]

