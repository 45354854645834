import React from "react";
import {FiCheck} from "react-icons/fi";
import {IoClose} from "react-icons/io5";
import persianDate from "persian-date";
import {Link} from "react-router-dom";
const ContactsItem = ({ id,userId,deviceName,email,date,description,speed,connection,ads,server,application}) => {

    const ok = <span className="flex flex-row bg-green-600 w-7 h-7 rounded-full justify-center items-center"><FiCheck size={23} className="text-white"/></span>;
    const no = <span className="flex flex-row bg-red-600 w-7 h-7 rounded-full justify-center items-center"><IoClose size={23} className="text-white"/></span>;



return(
    <div className="mt-6">
        <div className="flex flex-col gap-y-5 border rounded-lg p-4">
            <div className="grid grid-cols-1">
                <div className="text-lg flex flex-row justify-between items-center">
                    <div className="dark:text-gray-400 ">
                        <span>کاربر : </span>
                        <span className="font-bold sm:text-lg text-sm">{userId}</span>
                    </div>
                    <Link to={`/user/`+ id} className="text-sm sm:text-base bg-indigo-500 hover:bg-indigo-600 rounded-lg  text-gray-200 px-3 py-1">جزئیات</Link>
                </div>
            </div>
            <hr className="mb-1 md:mb-3"/>
            <div className="grid grid-cols-1">
                <div className="dark:text-gray-400">
                    <span>نام دستگاه : </span>
                    <span>{deviceName}</span>
                </div>
            </div>
            <div className="grid grid-cols-1">
                <div className="dark:text-gray-400">
                    <span>ایمیل : </span>
                    <span>{email}</span>
                </div>
            </div>
            <div className="grid grid-cols-5 bg-gray-200 dark:bg-gray-700 dark:text-gray-300 p-2 rounded-lg">
                <div key={id+'1'} className="flex flex-col justify-center items-center gap-y-2">
                    <span className="text-sm sm:text-base">سرعت</span>
                    {speed === "true" ? no : ok}
                </div>
                <div key={id+'2'} className="flex flex-col justify-center items-center gap-y-2">
                    <span className="text-sm sm:text-base">کانکشن</span>
                    {connection === "true" ? no : ok}
                </div>
                <div key={id+'3'} className="flex flex-col justify-center items-center gap-y-2">
                    <span className="text-sm sm:text-base">تبلیغات</span>
                    {ads === "true" ? no : ok}
                </div>
                <div key={id+'4'} className="flex flex-col justify-center items-center gap-y-2">
                    <span className="text-sm sm:text-base">سرور</span>
                    {server === "true" ? no : ok}
                </div>
                <div key={id+'5'} className="flex flex-col justify-center items-center gap-y-2">
                    <span className="text-sm sm:text-base">اپلیکیشن</span>
                    {application === "true" ? no : ok}
                </div>
            </div>
            <div className="grid grid-cols-1 mb-2">
                <div className="flex flex-col md:flex-row dark:text-gray-400">
                    <span className="mb-2 ml-2">توضیحات :</span>
                    <p className="text-justify ">{description}</p>
                </div>
            </div>
            <div className="dark:text-gray-400 text-sm flex flex-row justify-end">
                <span>تاریخ : </span>
                <span>{new persianDate.unix(date).format("HH-mm-ss YYYY-MM-DD")}</span>
            </div>
        </div>
    </div>
)
};

export default ContactsItem;
