import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    toast : []
}

const themeSlice = createSlice({
    name : 'toast',
    initialState,
    reducers :{
        showToast : (state,action)=>{
            state.toast =[{id:1,name:2}]
        }
    }
})

export const {showToast} = themeSlice.actions
export default themeSlice.reducer
