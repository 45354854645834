import React, { useState} from 'react';
import ConfigList from "../../components/config/ConfigList";

const Config = () => {

    const [showTabTitle,setSHowTabTitle] = useState("openvpn")
    const [showTab,setSHowTab] = useState(<ConfigList label="OpenVpn" protocol="openvpn"/>)

    const TabChange = (input) => {
      switch (input) {
          case "openvpn":
              setSHowTabTitle('openvpn')
              setSHowTab(<ConfigList label="OpenVpn" protocol="openvpn"/>)
              break
          case "ikev2":
              setSHowTabTitle('ikev2')
              setSHowTab(<ConfigList label="Ikev2" protocol="ikev2"/>)
              break
          case "cisco":
              setSHowTabTitle('cisco')
              setSHowTab(<ConfigList label="Cisco" protocol="cisco"/>)
              break
          case "v2ray":
              setSHowTabTitle('v2ray')
              setSHowTab(<ConfigList label="V2ray" protocol="v2ray"/>)

              break
      }
    }

    return (
        <div className="flex flex-col gap-y-4">
            <div className="grid grid-cols-4 text-center p-3 w-full  bg-white shadow dark:shadow-none  dark:bg-gray-700 rounded-lg gap-4">
                <button onClick={()=>TabChange("openvpn")} className={`${showTabTitle === "openvpn" ? "bg-indigo-500 text-gray-200" : "dark:bg-gray-600 bg-gray-300" } cursor-pointer flex flex-row justify-center items-center text-gray-800 dark:text-gray-200 px-4 py-2 text-center  rounded-lg`}>OpenVpn</button>
                <button onClick={()=>TabChange("ikev2")} className={`${showTabTitle === "ikev2" ? "bg-indigo-500 text-gray-200" : "dark:bg-gray-600 bg-gray-300" } cursor-pointer flex flex-row justify-center items-center text-gray-800 dark:text-gray-200 px-4 py-2 text-center  rounded-lg`}>Ikev2</button>
                <button onClick={()=>TabChange("cisco")} className={`${showTabTitle === "cisco" ? "bg-indigo-500 text-gray-200" : "dark:bg-gray-600 bg-gray-300" } cursor-pointer flex flex-row justify-center items-center text-gray-800 dark:text-gray-200 px-4 py-2 text-center  rounded-lg`}>Cisco</button>
                <button onClick={()=>TabChange("v2ray")} className={`${showTabTitle === "v2ray" ? "bg-indigo-500 text-gray-200" : "dark:bg-gray-600 bg-gray-300" } cursor-pointer flex flex-row justify-center items-center text-gray-800 dark:text-gray-200 px-4 py-2 text-center  rounded-lg`}>V2ray</button>
            </div>
            <div className="bg-white dark:bg-[#1f2937] dark:shadow-none dark:border dark:border-gray-600 rounded-lg shadow p-6 flex flex-col gap-y-8">
                {showTab}
            </div>
        </div>
    );
};

export default Config;
