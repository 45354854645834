import React from 'react';

const Profile = () => {
    return (
        <div className="w-full bg-white p-8 rounded-lg shadow ">
            Profile
        </div>
    );
};

export default Profile;
